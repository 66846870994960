.MuiAutocomplete-root .MuiAutocomplete-endAdornment {
	display: none;
}
.MuiAutocomplete-root .MuiAutocomplete-input {
	padding: 0 !important;
}
.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
	padding: 0 !important;
	border: none;
}
.MuiAutocomplete-root .MuiAutocomplete-inputRoot input {
	padding: 0 10px !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-root {
	border-radius: 0.375rem !important;
}
.MuiAutocomplete-root input {
	padding: 0 0.5rem !important;
	font-size: 16px;
	width: 100%;
	display: block;
	border-radius: 0.375rem !important;
	--tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #000 0),
		var(--tw-ring-shadow, 0 0 #000 0), var(--tw-shadow);
	--tw-border-opacity: 1;
	appearance: none;
	background-color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	border-width: 1px;
	margin: 0;
	height: 34px;
}
.MuiAutocomplete-root input:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(99, 102, 241, var);
	border-color: rgba(209, 213, 219, var);
}
.MuiAutocomplete-root input:disabled {
	color: #111;
	cursor: not-allowed;
}