.executor {
	background: white;
	display: flex;
	flex-direction: column;
	height: 100vh;
}
.executor__action {
	display: flex;
	padding: 10px;
}
.executor__action__1 {
	flex-grow: 1;
}
.executor__action__2 {
	width: 60px;
	padding-left: 10px;
}
.executor__action button {
	width: 100%;
	min-width: 0;
}
.executor__plan {
	flex-grow: 1;
	overflow: auto;
}
.executor__plan__item {
	border-top: 1px solid #eee;
}
.executor__plan__item span {
	display: block;
	padding: 5px 10px;
}
.executor__plan__item--active {
	background: #f9f9f9;
	font-weight: bold;
}
.executor__plan__item--sent {
	display: none;
}