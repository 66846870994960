.executor-form {
	padding: 25px;
	width: 400px;
	padding-top: 0;
}
.executor-form > div {
	margin-bottom: 15px;
}
.executor-form label {
	font-weight: 500;
	display: block;
}
.executor-form input:not([type="radio"]) {
	width: 100%;
	border-radius: 4px;
	border: 1px solid #ccc;
	padding: 5px 10px;
	font-size: 16px;
}